@import './reset.scss';
@import './colors.scss';
@import './breakpoints.scss';
@import './type.scss';


body {
    background-color: $grey;
}

