@import "../../styles/globals.scss";

.main {
    max-width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1000000;

    @include desktop {
        max-width: 80%;
    }

    @include large {
        max-width: 50%;
    }

    h1 {
        font-family: "Favorit Regular";
        font-size: 40px;
        color: $black;
        text-decoration: none;
        letter-spacing: 30px;
    }
  }
  