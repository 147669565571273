@import "../../styles/globals.scss";

.main {
    display: flex;
    flex-direction: row;
    overflow: hidden;    
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    margin-bottom: 50px;

    @include tablet {
        margin-bottom: 30px;
        
        //justify-content: flex-start;
    }

    @include desktop {
        margin-bottom: 10px;
        flex-wrap: nowrap;
    }

}

.main > div {
    
    flex-grow: 1;

    :last-child {
        padding-right: 0;
    }

    @include desktop {
        padding-right: 40px;
    }
}


// TODO: these width ratios need to be modified for different breakpoints

.event_listing_date {
    white-space: nowrap;   
    width: 50%; 
    margin-bottom: 3px;

    @include tablet {
        width: 50%; 
        margin-bottom: 0px;
    }

    @include desktop {
        width: 13%; 
    }
}

.event_listing_type {
    // white-space: nowrap;
    width: 50%;  
    text-align: right;

    @include tablet {
        width: 50%; 
    }

    @include desktop {
        text-align: left;
        width: 11%; 
    }
}

.event_listing_title {
    // white-space: nowrap;
    width: 100%;
    font-weight: bold;

    @include tablet {
        width: 50%;
    }

    @include desktop {
        width: 15%; 
        font-weight: normal;
    }
}

.event_listing_location {
    // white-space: nowrap;
    width: 100%;
    margin-bottom: 15px;

    @include tablet {
        text-align: right;
        width: 50%;
    }

    @include desktop {
        text-align: left;
        margin-bottom: 0px;
        width: 13%; 
    }
}

.event_listing_description {
    // white-space: nowrap;
    width: 100%;
    // flex-shrink: 1;
    @include tablet {
        width: 100%;

        p {
            margin-bottom: 5px;
        }
    }

    @include desktop {
        width: 39%;
    }
}

.event_listing_link {

    white-space: nowrap;
    width: 100%;
    text-align: right;
    
    @include tablet {
        text-align: left;
    }

    @include desktop {
        width: 9%;
    }
}

