@import "../styles/globals.scss";

.main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    h3 {
        width: 100%;
    }
}

.content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start; 
    width: 100%;

    @include tablet {
        flex-direction: row;
    }
}

.board_person_list_section {
    width: 100%;
    
    @include tablet {
        width: auto;
        margin-right: 80px;
    }
}

.board_person_list {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include tablet {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
}

.board_person_list_name {
    margin-bottom: 20px;
    width: fit-content;
}

.board_person_details {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    flex-grow: 1;

    @include desktop {
        flex-direction: row;
    }
}

.board_person_details_text {
    //margin-right: 60px;

    p {
        max-width: 400px;
    }

    @include desktop
     {
        margin-right: 60px;

        p {
            max-width: 600px;
        }
    }
}

.board_person_details_image {
    flex-grow: 1;
  
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;

    @include tablet {
        max-width: 300px;
        min-width: 150px;
    }

    

    img {
        width: 100%;
        object-fit: contain;
    }  
    
     
}