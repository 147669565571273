@import "../../styles/globals.scss";

.main {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}


.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    canvas {
        width: 100%;
        height: 100%;
    }
}

.image {
    min-width: 100%;
    min-height: 100%;
}