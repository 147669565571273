@import "../../styles/globals.scss";

.main {
  position: relative;
  width: auto;
  height: auto;

  color: $black;
  text-decoration: none;
  white-space: nowrap;
  
  border: none;

  &.top_nav {
    margin-right: 0px;
   
    background-color: white;
    padding: 5px 5px;

    &:last-child {
      margin-right: 0px;
    }

    @include desktop {
      margin-right: 20px;
    }
  }

  // TODO: handle font size across breakpoints
  // @include tablet {
  //   font-size: 16px;
  // }

  // @include desktop {
  //   font-size: 18px;
  // }

  // @include large {
  //   font-size: 20px;
  // }

  .underline {
    width: 0%;
    height: 3px;

    background-color: $green;
    transition: width 0.2s ease-in-out;
    
    margin-top: 0px;
  }

  &:hover {
    .underline {
      width: 100%;
    }
  }

  &:focus {
    .underline {
      width: 100%;
    }
  }

  &.active {
    .underline {
      width: 100%;
    }
  }
}

.external_link_button {
  display: inline-block;
  
  &.always_highlight .underline {
    width: 100%;
    margin-top: 0px;
  }

  &.compact .underline {
    margin-top: -2px;
    text-align: left;
  }

  // if we are always showing the underline, add an additional hover state
  &.always_highlight {
    &:focus, &:hover {
      color: $green;
    }
  }
}

.burger {
  height: 30px;
  width: 30px;
  position: fixed;
  top: 20px;
  right: 18px;
}