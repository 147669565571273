
  @mixin tablet {
    @media (min-width: 415px) {
      @content;
    }
  }
  
  @mixin desktop {
    @media (min-width: 834px) {
      @content;
    }
  }
  
  @mixin large {
    @media (min-width: 1440px) {
      @content;
    }
  }
  