@import "../../styles/globals.scss";

.main {
  width: 100vw;
  height: 80px;
  background-color: $white;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-items: center;

  padding: 15px 20px;
  padding-bottom: 30px;

  z-index: 3;

  @include tablet {

    padding: 15px 40px;
  }
}

.pageNav {

  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.menuButtons {
  display: flex; 
  align-items: center;
  justify-content: space-between;
  position: relative;
  
}

.mobileMenuButtons {
  width: 100vw;
  position: fixed;
  height: calc(100vh - 80px);
  left: 0;
  top: 80px;
  background-color: white;
  padding: 80px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}