@import "../../styles/globals.scss";

.main {
    width: 100vw;
    height: calc(100vh - 80px);

    display: flex;
    position: fixed;
    top: 80px;
    left: 0;
    
    background-color: white;

    z-index: 99;

    @include desktop {
        z-index: 2;
        width: 100%;
        height: auto;
        min-height: 200px;

    }
}

.subscribe {
    .subscribe_content {
        display: flex;
        width: 100%;
        margin-top: 80px;
        flex-direction: column;
        padding: 30px 20px;

        @include desktop {
            flex-direction: row;
        }
    }

    .subscribe_text_container {
        font-size: inherit;
        
    
        
        display: flex;
        justify-content: flex-end;

        

        @include desktop {
            flex-grow: 55;
            margin-right: 8%;
            p {
                max-width: 250px;
            }
        }
    }

    .subscribe_form_container {
        font-size: inherit;
        flex-grow: 45;
        margin-right: 40px;
    }

    .subscribe_email_input {
        font-size: inherit;
        border-bottom: 2px solid $black;
        padding-bottom: 10px;

        input {
            width: 100%;
        }
    }

    .subscribe_sign_up_button {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}

.contact {
    .contact_content {
        width: 100%;
        //margin-top: 80px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include desktop {
            margin-top: 80px;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    .contact_address, .contact_links, .contact_email {
        //margin-bottom: 5px;
        

        p {
            text-align: center;
        }

        @include desktop {
            margin-right: 100px;
            

            p {
                margin-bottom: 5px;
                text-align: left;
            }
        }
    }

    .contact_email {
        

        @include desktop {
          //  margin-right: 40px;
            width: 70px;
        }
    }

    .contact_address {
        @include desktop {
           // margin-right: 40px;
            width: 350px;
        }
    }

    .contact_links {
        @include desktop {
           // width: 60px;
        }
    }

    .contact_link_container {
        // slight hack to get links to line up nicely with address to the left of it
        margin-bottom: 2px;
        text-align: center;

        @include desktop {
            text-align: left;
           
        }
    }
}