@import "../styles/globals.scss";

.main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    // undo main styles to make room for intro
    // margin-top: 0;
    // height: unset;
    padding: 0;

    h1 {
        width: 100%;
    }
}

.intro {
    width: 100%;
    background-color: $white;
    position: relative;
    // TODO margin-top should be a global var
    padding: 20px;

    @include tablet {
        padding: 40px;

        p {
            max-width: 50%;
            padding-right: 20px;
            margin-bottom: 0;
        }
    }
}

.main_content {
    // reapply main styles on content
    height: calc(100vh - 80px);
    padding: 40px 40px;
    width: 100%;
    
}

.hero_award_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    height: auto;

    .hero_award_text {
        width: 100%;
        margin-bottom: 30px;

        h1 {
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 20px;
        }

        .hero_author_name {
            margin-bottom: 40px;
        }
    }

    .hero_award_image {
        width: 100%;

        img {
            min-height: unset;
        }
    }

    @include tablet {
        flex-direction: row;
        margin-bottom: 60px;
        height: auto;

        .hero_award_text { 
            width: 50%;
            padding-right: 40px;
            margin-bottom: 0px;
        }

        .hero_award_image {
            width: 50%;
        }
    }

    @include desktop {
        height: max(calc(100vh - 325px), 400px);
    }
}

.award_tiles {
    display: grid;
    grid-template-columns: repeat(1, calc(100vw - 44px));
    column-gap: 30px;
    row-gap: 50px;
    padding-bottom: 50px;

    @include tablet {
        display: grid;
        grid-template-columns: repeat(2, calc(50vw - 44px));
        row-gap: 120px;
        padding-bottom: 120px;
    }

    @include desktop {
        grid-template-columns: repeat(3, calc(33vw - 44px));

    }
}

.award_tile {
    display: flex;
    flex-direction: column;
    // width: 33%;
    // margin-bottom: 120px;

    h2 {
        margin-bottom: 20px;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 0;
    }

    .award_byline {
        // TODO: get think font weight
        font-weight: 300;
    }

    img {
        max-height: 300px;
        object-fit: cover;

        display: none;
        @include tablet {
            display: block;
        }
    }

    // width: calc(33vw - 20px);
}