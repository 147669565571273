@import "../styles/globals.scss";

.main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
        width: 100%;
    }
}

.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
        margin-bottom: 0px;
    }

    @include tablet {
        flex-wrap: nowrap;
        justify-content: flex-start;

        h3 {
            margin-bottom: 40px;
        }
        
    }
}

.filter_item {
    font-weight: bold;
    padding: 3px;

    @include tablet {
        padding: 0px;
        margin-right: 40px;
    }
}

.listings {
    display: flex;
    min-height: 80vh;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}