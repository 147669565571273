@font-face {
    font-family: 'Favorit Regular';
    src: url('./fonts/ABCFavorit-Regular.woff2') format('woff2'),
        url('./fonts/ABCFavorit-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

*, h1, h2, h3, h4, h5, p, li, a, div {
    font-family: "Favorit Regular", Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.4;

    @include tablet {
        font-size: 14px;
    }
    
    @include desktop {
        font-size: 14px;
    }
    
    @include large {
        font-size: 16px;
    }

    &::selection {
        background-color: #42f95a;
    }
}

h1 {
    font-size: 32px;
    line-height: 1.5;
}

h2 {
    font-size: 22px;
    line-height: 1.2;
}

h3 {
    text-transform: uppercase;
    margin-bottom: 40px;
}

p {
    margin-bottom: 20px;
}

ul li {
    list-style: disc;
    margin-left: 15px;
    margin-bottom: 20px;
    padding-left: 5px;
}

// TODO: Handle styles for nested lists?

