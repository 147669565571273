@import "../styles/globals.scss";


.main {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    @include tablet {
        flex-direction: row;
    }

}

.about {
    width: 100%;

    @include tablet {
        width: 40%;
        padding-right: 40px;
    }
}

// TODO: the breakpoint logic is alright, but the actual width we swap is not quite right for this page

.topics {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 50px;

    @include tablet {
        width: 60%;
        flex-direction: column;
        margin-top: 0px;
    }

    @include desktop {
        flex-direction: row;
    }

    h3 {
        width: 100%
    }
}

.topicTitles {
    padding-right: 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    position: sticky;
    margin-bottom: 30px;

    .topicTitlesLinkButtonContainer {
        line-height: 1.4;
        padding: 3px;
    }

    @include tablet {
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;

        .topicTitlesLinkButtonContainer {
            margin-right: 20px;
            margin-bottom: 10px;
            padding: 0px;
        }
    }

    @include desktop {
        flex-direction: column;
        flex-wrap: nowrap;
        width: 20%;
        margin-bottom: 0;

        .topicTitlesLinkButtonContainer {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.topicText {
    width: 100%;
    display: inline-block;

    @include tablet {
        width: 80%;
    }
}