@import "../styles/globals.scss";

.main {
    width: 100vw;
    margin-top: 80px;
    height: calc(100vh - 80px);
    background-color: $grey;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 50px 20px;
    padding-bottom: 100px;

    @include desktop {
        padding: 80px 40px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    

    &::-webkit-scrollbar {
        width: 9px;
        background-color: white;
    }

    &::-webkit-scrollbar-thumb {
        background: $green;
      }
}

